import { ProductCard, ProductDetail, ProductList } from "../components";
import {  Home, SignIn,SignUp,AddProduct, TermsAndConditions, CancelationAndRefund, ShippingAndDelivery,PrivacyPolicy, ContactUs, AboutUs, Offlinestore, AdminPanel} from "../pages";

export const authRoutes = [
    {
        path : '/login',
        element : <SignIn/>,
    },
    {
        path : '/signup',
        element : <SignUp/>,
    },
]

export const contentRoutes = [
    {
        path : '/products',
        element : <ProductCard/>,
    },
    {
        path : '/add-product',
        element : <AddProduct/>,
    },
    {
        path : '/product/:id',
        element : <ProductDetail/>,
    },
    {
        path : '/productlist',
        element : <ProductList/>,
    },
    {
        path : '/productlist/:category',
        element : <ProductList/>,
    },
    {
        path : '/terms',
        element : <TermsAndConditions/>,
    },
    {
        path : '/cancellation',
        element : <CancelationAndRefund/>,
    },
    {
        path : '/shipping',
        element : <ShippingAndDelivery/>,
    },
    {
        path : '/privacypolicy',
        element : <PrivacyPolicy/>,
    },
    {
        path : '/contactus',
        element : <ContactUs/>,
    },
    {
        path : '/aboutus',
        element : <AboutUs/>,
    },
    {
        path : '/offlinestore',
        element : <Offlinestore/>,
    },
    {
        path : '/admin-panel',
        element : <AdminPanel/>,
    },
    {
        path : '*',
        element : <Home/>,
    },
];

