import React, { useState, useEffect, useContext  } from 'react';
import { FaPlus, FaRupeeSign } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa";
import toast from 'react-hot-toast';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useCartContext } from '../context';
import { createAddressURL } from '../api/api';
import "../App.css";
import { useWishlistContext } from '../context'; // adjust the path if needed
 
const Cart = () => {
  const navigate = useNavigate();
  const { Decrease, Increase, Cart, removeItem } = useCartContext();
  const [showAddAddressModal, setShowAddAddressModal] = useState(false);
  const { addToWishlist } = useWishlistContext();
  const [promoCode, setPromoCode] = useState('');
  const [validPromo, setValidPromo] = useState(false);
  const [promoApplied, setPromoApplied] = useState(false); // New state to track if Apply button is clicked
  const [discountAmount, setDiscountAmount] = useState(0);
  const promoCodes = JSON.parse(localStorage.getItem("promoCodes") || "[]");
 
 
  const handleAddToWishlist = (item) => {
    addToWishlist(item); // Call addToWishlist function to add the item
};
 
    // Calculate initial total and total after discount
    const Price = Cart.reduce((total, item) => total + item.MRP_price * item.productcount, 0);
    const totalDiscountAmount = Cart.reduce((totalDiscount, item) => {
      const discount = (item.MRP_price - item.selling_price) * item.productcount;
      return totalDiscount + (discount > 0 ? discount : 0);
    }, 0) || 0;
 
    const totalAmountAfterDiscount = Price - totalDiscountAmount - discountAmount;
 
    useEffect(() => {
      localStorage.setItem("totalAmountAfterDiscount", JSON.stringify(totalAmountAfterDiscount));
      if (validPromo) {
        localStorage.setItem("appliedPromo", JSON.stringify({ code: promoCode, discountPercentage: discountAmount > 0 ? (discountAmount / (Price - totalDiscountAmount) * 100).toFixed(2) : 0 }));
      } else {
        localStorage.removeItem("appliedPromo");
      }
    }, [totalAmountAfterDiscount, promoCode, validPromo, discountAmount]);
 
  const handlePromoCodeChange = (e) => {
    setPromoCode(e.target.value);
    setValidPromo(false);
    setDiscountAmount(0);
  };
 
  const handleApplyPromo = () => {
    setPromoApplied(true); // Set promoApplied to true to show feedback messages
 
    if (!promoCode) {
      toast.error("Please select a promo code to apply.");
      return;
    }
 
    const selectedPromo = promoCodes.find(promo => promo.code === promoCode);
    if (selectedPromo) {
      setValidPromo(true);
      const discount = (selectedPromo.discount / 100) * (Price - totalDiscountAmount);
      setDiscountAmount(discount);
      toast.success(`Promo code applied! You saved ₹${discount.toFixed(2)}`);
    } else {
      setValidPromo(false);
      setDiscountAmount(0);
      toast.error("Invalid Promo Code.");
    }
  };
 
 
  const pf = JSON.parse(localStorage.getItem('userData')) || {};
  const ad = JSON.parse(localStorage.getItem('address')) || [];
  const [newAddress, setNewAddress] = useState({
    user_id: pf.user_id || '',
    addressLine1: ad.addressLine1 || '',
    addressLine2: ad.addressLine2 || '',
    Landmark: ad.Landmark || '',
    city: ad.city || '',
    state: ad.state || '',
    pincode: ad.pincode || '',
  });
 
  const ef = JSON.parse(localStorage.getItem('selectedAddress')) || {};
  const [selectedAddress, setSelectedAddress] = useState({
    addressLine1: ef.addressLine1 || '',
    addressLine2: ef.addressLine2 || '',
    Landmark: ef.Landmark || '',
    city: ef.city || '',
    state: ef.state || '',
    pincode: ef.pincode || '',
  });
 
  const [addresses, setAddresses] = useState([]);
 
  useEffect(() => {
    const storedAddresses = JSON.parse(localStorage.getItem("address")) || [];
    setAddresses(storedAddresses);
 
    if (storedAddresses.length === 1) {
      setSelectedAddress(storedAddresses[0]);
    }
  }, []);
 
  const handleAddressSelect = (address) => {
    setSelectedAddress(address);
  };
 
  const toggleModal = () => {
    setShowAddAddressModal(!showAddAddressModal);
  };
 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAddress({ ...newAddress, [name]: value });
  };
 
  const handleAddAddress = async (e) => {
    e.preventDefault();
 
    if (!newAddress.addressLine1 || !newAddress.city || !newAddress.state || !newAddress.pincode) {
      toast.error("Please fill all required fields.");
      return;
    }
 
    try {
      await axios.post(createAddressURL, { ...newAddress }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
 
      const id = Date.now();
      const updatedAddresses = [...addresses, { ...newAddress, id }];
      setSelectedAddress({ ...newAddress, id });
      localStorage.setItem("address", JSON.stringify(updatedAddresses));
      setAddresses(updatedAddresses);
      toggleModal();
      toast.success("Address added successfully!");
    } catch (error) {
      console.error('Error adding address:', error);
      toast.error(`An error occurred: ${error.message}`);
    }
  };
 
  const handleCheckout = () => {
    if (!selectedAddress || !selectedAddress.addressLine1) {
      toast.error("Please select an address to proceed.");
      return;
    }
    localStorage.setItem("selectedAddress", JSON.stringify(selectedAddress));
    navigate("/checkout");
  };
 
  return (
    <>
      {Cart?.length > 0 ? (
        <div className="md:py-10 w-screen h-fit bg-gray-100">
          <h1 className="text-4xl font-extrabold text-center text-gray-800">Your Cart</h1>
 
          <div className="flex flex-col md:flex-row md:py-10 w-screen bg-gray-100">
            <div className="w-full md:w-2/5 px-4 h-fit overflow-y-auto rounded-xl md:mx-8 mb-4 md:mb-0">
              <div className="bg-gray-300 shadow-md rounded-lg mb-5 py-3 px-5 flex justify-between items-center">
                <span className="text-sm font-medium">Total Unique Items</span>
                <button className="px-3 py-1 border rounded text-sm">{Cart?.length}</button>
              </div>
 
              {Cart?.map((item) => (
                <div key={item._id} className="bg-white shadow-sm rounded-lg mb-4 p-4 flex gap-5">
                  <div className="flex flex-col items-center gap-4">
                    <img src={item.image[0]} alt="product" className="h-28 w-28 rounded-md object-cover" />
                    <div className="flex items-center gap-2 border rounded-full px-2 py-1">
                      <p>Qty: </p>
                      <button onClick={() => Decrease(item)} className="text-lg"><FaMinus /></button>
                      <span className="px-2">{item.productcount}</span>
                      <button onClick={() => Increase(item)} className="text-lg"><FaPlus /></button>
                    </div>
                  </div>
 
                  <div className="flex-1">
                    <h3 className="text-lg font-semibold">{item.name}</h3>
                    <p className="text-gray-600 text-sm">Color: {item.product_color}</p>
                    <p className="text-gray-600 text-sm">Seller: {item.brand}</p>
 
                    <div className="mt-2">
                      <span className="line-through text-gray-500 mr-2">{`₹${item.MRP_price}`}</span>
                      <span className="text-lg font-bold text-black">{`₹${item.selling_price}`}</span>
                      <span className="ml-2 text-green-600 text-sm">
                        {((item.MRP_price - item.selling_price) / item.MRP_price * 100).toFixed(0)}% Off
                      </span>
                    </div>
 
                    <div className="mt-3 flex items-center gap-4">
                    <button
                        className="text-sm text-green-600 font-medium"
                        onClick={() => handleAddToWishlist(item)}
                    >
                        Add to Wishlist
                    </button>
                      <button
                        onClick={() => { removeItem(item); toast.success("Item Removed"); }}
                        className="text-sm text-red-500 font-medium"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
 
            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div className="bg-white shadow-md rounded-lg p-5">
                <h2 className="text-xl font-bold mb-4 text-gray-800">Select Address</h2>
 
                <div className="space-y-4 max-h-64 overflow-y-auto">
                  {addresses.length > 0 ? (
                    addresses.map((address) => (
                      <div
                        key={address.id}
                        className={`p-3 border rounded-lg cursor-pointer transition duration-300 ${selectedAddress.id === address.id ? 'border-blue-500 bg-blue-50' : 'border-gray-300 hover:border-blue-300'}`}
                        onClick={() => handleAddressSelect(address)}
                      >
                        <p className="text-sm text-gray-700">
                          {`${address.addressLine1}, ${address.addressLine2}, ${address.Landmark}, ${address.city}, ${address.state} - ${address.pincode}`}
                        </p>
                      </div>
                    ))
                  ) : (
                    <p className="text-sm text-red-500">No addresses available. Please add an address.</p>
                  )}
                </div>
 
                <button
                  className="mt-6 w-full bg-green-600 text-white py-3 rounded-md font-semibold hover:bg-green-700 transition duration-300"
                  onClick={toggleModal}
                >
                  Add New Address
                </button>
              </div>
            </div>
 
            <div className="w-full md:w-1/4 px-4">
              <div className="bg-white shadow-md rounded-lg p-5">
                <h2 className="text-xl font-bold mb-4 text-gray-800">Price Details</h2>
 
                <div className="flex justify-between mb-3">
                  <span className="text-base text-gray-600">Price ({Cart?.length} items)</span>
                  <span className="text-base font-semibold text-gray-900">₹{Price.toFixed(2)}</span>
                </div>
 
                <div className="flex justify-between mb-3">
                  <span className="text-base text-gray-600">Discount</span>
                  <span className="text-base font-semibold text-green-600">-₹{totalDiscountAmount.toFixed(2)}</span>
                </div>
               
                <div className="my-3">
  <label htmlFor="promoCode" className="block text-gray-700 text-sm font-semibold mb-2">
    Apply Promo Code
  </label>
  <div className="flex gap-2">
    {promoCodes.length > 0 ? (
      <>
        <select
          id="promoCode"
          value={promoCode}
          onChange={handlePromoCodeChange}
          className="border rounded-lg p-1 w-full text-gray-700"
        >
          <option value="" disabled>Select Promo Code</option> {/* Placeholder */}
          {promoCodes.map((promo) => (
            <option key={promo.code} value={promo.code}>
              {promo.code}
            </option>
          ))}
        </select>
        <button
          className="bg-blue-600 text-white px-4 rounded-lg"
          onClick={handleApplyPromo}
          disabled={!promoCode} // Disable if no code is selected
        >
          Apply
        </button>
      </>
    ) : (
      // Display this message if no promo codes are available
      <p className="text-gray-500">No promo codes available</p>
    )}
  </div>
 
  {/* Show feedback only after Apply is clicked */}
  {promoApplied && validPromo && (
    <p className="text-green-600 mt-2">
      Promo code applied successfully! You’re getting a {promoCodes.find(promo => promo.code === promoCode)?.discount}% discount.
    </p>
  )}
  {promoApplied && !validPromo && (
    <p className="text-red-600 mt-2">Invalid Promo Code. Please try again.</p>
  )}
 
                <hr className="my-3 border-gray-200" />
 
                <div className="flex justify-between mb-4 font-semibold text-gray-900">
                  <span className="text-lg">Total Amount</span>
                  <span className="text-lg">₹{totalAmountAfterDiscount.toFixed(2)}</span>
                </div>
 
                <p className="text-sm text-green-500 mb-4">
                  You will save ₹{(totalDiscountAmount + discountAmount).toFixed(2)} on this order.
                </p>
 
                <div className='mb-1'>
                  <h2 className="text-lg font-semibold">Deliver to Address</h2>
                </div>
                {selectedAddress && selectedAddress.addressLine1 ? (
                  <div className="mb-2">
                    <input type="text" name="selectedAddress" className="hidden" />
                    <p className="flex pb-1">
                      {`${selectedAddress.addressLine1} ${selectedAddress.addressLine2}, ${selectedAddress.city}, ${selectedAddress.state} - ${selectedAddress.pincode}`}
                    </p>
                  </div>
                ) : (
                  <div className="text-center text-sm text-red-500">No address selected. Please add or select an address.</div>
                )}
              </div>
                <button
                  className="w-full bg-blue-600 text-white py-3 rounded-md font-semibold hover:bg-blue-700 transition duration-300"
                  onClick={handleCheckout}
                >
                  Proceed to Checkout
                </button>
              </div>
            </div>
            </div>
 
          {/* Add Address Modal */}
          {showAddAddressModal && (
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="bg-white rounded-lg shadow-lg p-5 w-11/12 md:w-1/2">
                <h2 className="text-lg font-bold mb-4">Add New Address</h2>
                <form onSubmit={handleAddAddress}>
                  <input
                    type="text"
                    name="addressLine1"
                    placeholder="Address Line 1"
                    value={newAddress.addressLine1}
                    onChange={handleInputChange}
                    className="border p-2 w-full mb-3"
                    required
                  />
                  <input
                    type="text"
                    name="addressLine2"
                    placeholder="Address Line 2 (optional)"
                    value={newAddress.addressLine2}
                    onChange={handleInputChange}
                    className="border p-2 w-full mb-3"
                  />
                  <input
                    type="text"
                    name="Landmark"
                    placeholder="Landmark"
                    value={newAddress.Landmark}
                    onChange={handleInputChange}
                    className="border p-2 w-full mb-3"
                  />
                  <input
                    type="text"
                    name="city"
                    placeholder="City"
                    value={newAddress.city}
                    onChange={handleInputChange}
                    className="border p-2 w-full mb-3"
                    required
                  />
                  <input
                    type="text"
                    name="state"
                    placeholder="State"
                    value={newAddress.state}
                    onChange={handleInputChange}
                    className="border p-2 w-full mb-3"
                    required
                  />
                  <input
                    type="text"
                    name="pincode"
                    placeholder="Pincode"
                    value={newAddress.pincode}
                    onChange={handleInputChange}
                    className="border p-2 w-full mb-3"
                    required
                  />
                  <button
                    type="submit"
                    className="bg-green-600 text-white py-2 rounded-md w-full hover:bg-green-700 transition duration-300"
                  >
                    Add Address
                  </button>
                </form>
                <button
                  className="mt-4 text-red-500"
                  onClick={toggleModal}
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center py-16 sm:py-24">
          <div className="text-center my-8">
            <h1 className="text-4xl font-extrabold text-gray-800">Your Cart is Empty</h1>
          </div>
          <img
            src="./image/EmptyCart.gif"
            alt="Empty Cart"
            className="w-3/4 sm:w-1/2 mb-4"
            style={{ background: 'transparent' }}
          />
          <Link
            to="/"
            className="
              flex items-center justify-center
              text-black text-sm sm:text-base md:text-lg
              text-center hover:text-blue-900
              bg-yellow-500
              h-10 sm:h-12 md:h-14
              w-3/4 sm:w-1/3 md:w-1/6
              mx-auto
              rounded-md"
          >
            <b>SHOP NOW</b>
          </Link>
        </div>
      )}
    </>
  );
};
 
export default Cart;
 
 