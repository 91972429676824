const wishlistReducer = (state, action) => {
  // Handling the addition of a product to the wishlist
  if (action.type === "ADD_TO_WISHLIST") {
    // Find if the product already exists in the wishlist based on _id
    let existing_product = state?.Wishlist?.find((item) => item?._id === action?.payload?._id);
    if (existing_product) {
      // If the product is already in the wishlist, return the current state (no changes)
      return state;
    } else {
      // If not, add the new product to the wishlist array
      return { ...state, Wishlist: [...(state?.Wishlist || []), action?.payload] };
    }
  }

  // Handling the removal of a product from the wishlist
  if (action.type === "REMOVE_TO_WISHLIST") {
    // Filter out the product with the matching _id
    let remove = (state?.Wishlist || []).filter((item) => item?._id !== action?.payload);
    return { ...state, Wishlist: remove };
  }

  // Default case: return the current state if no relevant action type is matched
  return state;
};

export default wishlistReducer;
