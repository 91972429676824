import React, { useEffect, useState, useRef } from "react";
import { FaRegHeart } from "react-icons/fa6";
import { useAuthContext, useCartContext, useWishlistContext } from "../../context";
import WishlistPage from "../../pages/Wishlistpage";
import Notification from "../../pages/Notification";
import { IoIosArrowDown } from "react-icons/io";
import CategoryModal from "../Modal/CategoryModal";
import { useNotificationContext } from "../../context";  // Import the hook
import { BiDotsVerticalRounded } from "react-icons/bi"; // Added missing import
import Sidebar from "./Sidebar";
import SearchBar from "../SearchBar/SearchBar"; // Added missing import
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  const { Cart } = useCartContext();
  const location = useLocation();
  const navigate = useNavigate();
  const { token, logoutHandler } = useAuthContext();
  const { Wishlist } = useWishlistContext();
  const { notificationCount } = useNotificationContext(); // Use notification context
  const [slider, setSlider] = useState(false);  // Sidebar visibility controlled by this state
  const [wishlist, setWishlist] = useState(false);
  const [notification, SetNotification] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const products = JSON.parse(localStorage.getItem("products") || "[]");
  const [groupedProducts, setGroupedProducts] = useState({});
  const dropdownRef = useRef(null);  // Define dropdownRef

  useEffect(() => {
    const categorizedProducts = products.reduce((acc, product) => {
      const { category } = product;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(product);
      return acc;
    }, {});
    setGroupedProducts(categorizedProducts);
  }, [products]);

  // Handle Category Select and Dropdown
  const handleProductSelect = (productId) => {
    navigate(`/product/${productId}`);
    setActiveDropdown(null);
    setIsModalOpen(false);
  };

  const handleMouseEnter = (category) => {
    if (window.innerWidth >= 768) setActiveDropdown(category);
  };

  const handleMouseLeave = () => {
    if (window.innerWidth >= 768) setActiveDropdown(null);
  };

  const handleCategoryClick = (category) => {
    if (window.innerWidth < 768) {
      setSelectedCategory(category);
      setIsModalOpen(true);
    } else {
      setActiveDropdown((prev) => (prev === category ? null : category));
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && window.innerWidth < 768) {
        setActiveDropdown(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Sidebar only opens on hamburger click
  const handleHamburgerClick = () => {
    setSlider(true);
  };

  return (
    <>
      <Sidebar
        slider={slider}
        setSlider={setSlider}
        token={token}
        logoutHandler={logoutHandler}
        SetNotification={SetNotification}
      />
      <WishlistPage wishlist={wishlist} setWishlist={setWishlist} />
      <Notification SetNotification={SetNotification} notification={notification} />

      {/* Main Navbar */}
      <nav className="w-screen sm:flex flex-col sm:flex-row md:px-4 items-center justify-center sm:justify-between py-2 z-30 bg-white sticky top-0 relative shadow-md">
        <div className="flex justify-between mx-4 items-center z-50 sticky top-0 relative">
          <Link to="/">
            <img src="./image/logo.jpeg" alt="Logo" style={{ height: "2.2cm", width: "4.5cm" }} />
          </Link>
          {/* Sidebar opener for mobile positioned at top-right */}
          <div className="sm:hidden absolute top-3 right-3" onClick={handleHamburgerClick}>
            <BiDotsVerticalRounded size={25} className="cursor-pointer" />
            {notificationCount > 0 && (
              <span className="absolute -top-2 -right-2 bg-red-500 text-white font-bold w-4 h-4 rounded-full flex items-center justify-center text-xs">!</span>
            )}
          </div>
        </div>
        <div className="flex items-center gap-8 justify-evenly lg py-1 z-100">
          <p className="md:hidden md:flex">
            <SearchBar />
          </p>
        </div>
        <div>
          <ul className="flex items-center gap-8 justify-evenly lg py-2 z-100">
            <li className="hidden md:flex">
              <SearchBar />
            </li>
            <li onClick={() => navigate("/productlist")}>
              <Link className="flex gap-0 flex-col items-center relative">
                <img src="./image/product.png" alt="Product Icon" className="m-0 p-0 text-3xl" style={{ height: "30px", width: "30px" }} />
                <span className="md:flex p-0 m-0 sm:block ms-5">
                  <b>View All Products</b>
                </span>
              </Link>
            </li>
            <li onClick={() => navigate("/Cart")}>
              <Link className="flex gap-0 flex-col items-center relative">
                <img src="./image/shopping-cart.png" alt="Shopping Cart Icon" className="m-0 p-0 text-3xl" style={{ height: "30px", width: "30px" }} />
                <span className="md:flex p-0 m-0">
                  <b>Cart</b>
                  {Cart?.length > 0 && (
                    <span className="bg-red-500 text-white font-bold px-[6px] rounded-full text-sm absolute top-[-17%] right-[-8px]">
                      {Cart?.length}
                    </span>
                  )}
                </span>
              </Link>
            </li>
            <li>
              <Link className="flex flex-col items-center relative" style={{ color: "red" }} onClick={() => setWishlist(true)}>
                <FaRegHeart className="text-3xl p-0 m-0" />
                <span className="md:flex" style={{ color: "black" }}>
                  <b>Wishlist</b>
                  {Wishlist?.length > 0 && (
                    <span className="bg-red-500 text-white font-bold px-[6px] rounded-full text-sm absolute top-[-15%] right-0">
                      {Wishlist?.length}
                    </span>
                  )}
                </span>
              </Link>
            </li>
            {/* Sidebar opener for desktop */}
            <li className="hidden sm:flex relative cursor-pointer" onClick={handleHamburgerClick}>
              <BiDotsVerticalRounded size={30} />
              {notificationCount > 0 && (
                <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-500 text-white font-bold w-4 h-4 rounded-full flex items-center justify-center text-xs">!</span>
              )}
            </li>
          </ul>
        </div>
      </nav>

      <hr />

      {/* Category Menu */}
      {location.pathname === "/product/:id" ? null : (
        <div className="py-2 w-full md:flex justify-center bg-gradient-to-r from-blue-50 to-blue-100 z-20">
          <nav className="max-w-7xl w-full">
            <ul className="flex items-center justify-evenly font-bold font-roboto text-gray-800" ref={dropdownRef}>
              {Object.keys(groupedProducts).map((category) => (
                <li 
                  className="relative group" 
                  key={category}
                  onClick={() => handleCategoryClick(category)}
                  onMouseEnter={() => handleMouseEnter(category)}
                  onMouseLeave={handleMouseLeave}
                >
                  <span className="flex flex-col sm:flex-row items-center cursor-pointer px-3 py-2 sm:px-0 rounded-md transition-colors duration-500">
                    <span>{category}</span>
                    <IoIosArrowDown className="mt-1 sm:ml-1 sm:mt-0" />
                  </span>
                  <ul className={`dropdown-content ${activeDropdown === category ? "show" : ""}`}>
                    {groupedProducts[category]?.map((product) => (
                      <li
                        key={product._id}
                        onClick={() => handleProductSelect(product._id)}
                        className="px-4 py-2 hover:bg-blue-100 cursor-pointer transition-colors duration-300"
                      >
                        {product.name}
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      )}

      {/* Render Category Modal for mobile view */}
      <CategoryModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        items={groupedProducts[selectedCategory] || []}
        onItemClick={handleProductSelect}
      />
    </>
  );
};

export default Navbar;
