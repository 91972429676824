import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCartContext } from '../context';
import axios from 'axios';
import { paymentURL, verify_paymentURL, invoiceURL } from "../api/api";

const Checkout = () => {
  const { Cart } = useCartContext();
  const navigate = useNavigate();
  const [paymentMethod, setPaymentMethod] = useState('');
  const [appliedPromo, setAppliedPromo] = useState(null);
  const [subtotal, setSubtotal] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);

  // Retrieve user and address details directly from localStorage
  const userData = JSON.parse(localStorage.getItem('userData')) || {};
  const selectedAddress = JSON.parse(localStorage.getItem('selectedAddress')) || {};
  const newAddress = {
    firstName: userData.firstName || '',
    lastName: userData.lastName || '',
    mobile: userData.mobile || '',
    email: userData.email || '',
    addressLine1: selectedAddress.addressLine1 || '',
    addressLine2: selectedAddress.addressLine2 || '',
    Landmark: selectedAddress.Landmark || '',
    city: selectedAddress.city || '',
    state: selectedAddress.state || '',
    pincode: selectedAddress.pincode || '',
  };

  useEffect(() => {
    const calculatedSubtotal = Cart.reduce((total, item) => total + item.selling_price * item.productcount, 0);
    setSubtotal(calculatedSubtotal);

    const promoDetails = JSON.parse(localStorage.getItem("appliedPromo")) || null;
    if (promoDetails) {
      setAppliedPromo(promoDetails);
      const calculatedDiscount = (promoDetails.discountPercentage / 100) * calculatedSubtotal;
      setDiscountAmount(calculatedDiscount);
    }
  }, [Cart]);

  const totalAmount = subtotal - discountAmount;

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const createOrder = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!newAddress.addressLine1) {
        alert('Shipping address is missing! Please select an address.');
        return null;
      }
  
      // Step 1: Create the invoice
      const invoiceResponse = await axios.post(invoiceURL, {
        amount: totalAmount,
        currency: 'INR',
        description: 'Purchase from DigiUncle Creations',
        customer: {
          name: userData.firstName,
          email: userData.email,
          contact: userData.mobile,
        },
        notes: {
          address: newAddress.addressLine1,
        },
      });
  
      const { id: invoice_id } = invoiceResponse.data;
  
      // Step 2: Create the order and pass the invoice ID
      const response = await axios.post(paymentURL, {
        products: Cart,
        totalAmount,
        appliedPromo,
        shipping_address: newAddress,
        invoice_id, // Include the invoice ID with the order data
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      return response.data;
    } catch (error) {
      console.error('Order creation failed:', error);
      alert('Order creation failed! Please try again.');
      return null;
    }
  };
  

  const handleRazorpayPayment = async () => {
    const isLoaded = await loadRazorpayScript();
    if (!isLoaded) {
      alert("Failed to load Razorpay SDK.");
      return;
    }
  
    const { firstName = '', email = '', mobile = '' } = userData;
    const orderData = await createOrder();
  
    if (!orderData) return;
  
    const { orderId, amount, currency, key_id } = orderData;
    let verificationResponse; // Define `verificationResponse` outside the try block
  
    const options = {
      key: key_id,
      amount,
      currency,
      name: 'DigiUncle Creations',
      description: `Thank you for your purchase!`,
      order_id: orderId,
      handler: async (response) => {
        try {
          verificationResponse = await axios.post(verify_paymentURL, {
            orderId,
            paymentId: response.razorpay_payment_id,
            signature: response.razorpay_signature,
            invoice_id: response.razorpay_invoice_id,
            orderDetails: {
              products: Cart.map((product) => ({
                SKU_id: product.SKU_id,
                name: product.name,
                price: product.selling_price,
                quantity: product.productcount,
              })),
              totalAmount,
              appliedPromo,
              shipping_address: newAddress,
              user_id: userData.user_id,
            },
          });
  
          alert("Payment successful!");
        } catch (error) {
          console.error("Payment verification failed:", error);
          alert("Payment verification failed, but your order has been placed.");
        } finally {
          // Clear cart and redirect to orders regardless of verification outcome
          localStorage.setItem("Cart", JSON.stringify([]));
  
          // Update local storage with new orders
          const { orders } = verificationResponse?.data || {};
          if (orders) {
            localStorage.setItem("orders", JSON.stringify(orders));
            navigate("/orders");
          } else {
            console.error("Orders not found in response:", verificationResponse?.data);
            alert("Error: Unable to fetch updated orders, but your order has been saved.");
            navigate("/orders");
          }
        }
      },
      prefill: {
        name: firstName,
        email: email,
        contact: mobile,
      },
      notes: {
        address: "Customer's address details here",
      },
      theme: {
        color: "#F37254",
      },
    };
  
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };
  
  const handleContinue = (event) => {
    event.preventDefault();
  
    if (!paymentMethod) {
      alert('Please select a payment method.');
      return;
    }
  
    if (paymentMethod === 'Pay Online') {
      handleRazorpayPayment();
    } else {
      alert('Order placed successfully with Cash on Delivery!');
      localStorage.setItem('Cart', JSON.stringify([]));
      window.location.href = '/orders';
    }
  };
  

  return (
    <>
      <div className="text-center my-5">
        <h1 className="text-3xl font-bold text-black">ORDER DETAILS</h1>
      </div>

      <div className="p-5 md:px-10 lg:px-20 flex flex-col lg:flex-row gap-6">
        {/* Cart Items Section */}
        <div className="w-full lg:w-1/2 border rounded-lg">
          <h2 className="bg-gray-200 font-medium text-2xl px-4 py-2">IN YOUR CART ({Cart.length})</h2>
          <h3 className="font-medium text-xl px-4 py-2 border-b">ARRIVES</h3>

          {Cart.map((item) => (
            <div className="flex gap-4 border-b p-4" key={item._id}>
              <div className="w-1/2 h-45 md:w-1/3">
                <img src={item.image[0]} className="w-full h-full object-cover" alt={item.name} />
              </div>
              <div className="flex-1">
                <p className="font-medium">{item.name}</p>
                <p>Style</p>
                <p>Qty: {item.productcount}</p>
                <p>Price: ₹{item.selling_price} (per piece)</p>
              </div>
            </div>
          ))}

          <div className="py-3 px-5">
            <Link to="/cart" className="text-blue-600 hover:underline hover:text-blue-800 duration-300">
              Edit Cart
            </Link>
          </div>
        </div>

        {/* Summary Section */}
        <div className="w-full lg:w-1/4 border rounded-lg">
          <h2 className="bg-gray-200 font-medium text-2xl px-4 py-2">SUMMARY</h2>
          <div className="px-4 py-3">
            <div className="flex justify-between py-2">
              <span>SUBTOTAL</span> <span>₹{subtotal.toFixed(2)}</span>
            </div>
            <div className="flex justify-between py-2">
              <span>SHIPPING & HANDLING</span> <span className="text-green-500">Free</span>
            </div>
            {appliedPromo && discountAmount > 0 && (
             <div className="flex flex-col py-2">
             <span>Promo Code: {appliedPromo.code}</span>
             <span className="text-green-500">{appliedPromo.discountPercentage}% OFF</span>
             <span className="text-green-500">- ₹{discountAmount.toFixed(2)}</span>
           </div>
            )}
            <div className="flex justify-between text-xl font-semibold py-2">
              <span>TOTAL</span> <span>₹{totalAmount.toFixed(2)}</span>
            </div>
          </div>
        </div>

        {/* Shipping and Payment Section */}
        <div className="w-full lg:w-1/2 border rounded-lg bg-gray-50 p-5">
          <strong className="block text-center text-red-500 text-lg mb-3">
            {`${newAddress.firstName} ${newAddress.lastName}`}
          </strong>
          <hr className="my-3" />
          <div className="text-center">
            <h3 className="flex justify-center gap-2">
              <b>Phone-No.:</b> <span>{newAddress.mobile}</span>
            </h3>
          </div>

          <h4 className="text-lg font-semibold mt-5 text-center">Deliver To Address</h4>
          <p className="text-center mt-2">
            {`${newAddress.addressLine1}, ${newAddress.addressLine2}, ${newAddress.Landmark}, ${newAddress.city}, ${newAddress.state} - ${newAddress.pincode}`}
          </p>

          {/* Payment Method */}
          <div className="mt-5">
            <h2 className="font-medium">
              Payment Method <span className="text-red-500">*</span>
            </h2>
            {['Pay Online'].map((method) => (
              <div key={method} className="mt-2">
                <label>
                  <input
                    type="radio"
                    name="paymentMethod"
                    value={method}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  />{' '}
                  {method}
                </label>
              </div>
            ))}
          </div>

          {/* Continue to Billing Button */}
          <div className="flex justify-between items-center mt-6 px-5 py-3">
            <p className="font-bold">
              Required <span className="text-red-500">*</span>
            </p>
            <button
              onClick={handleContinue}
              className="bg-orange-500 text-white text-lg px-4 py-2 rounded-md hover:bg-orange-600 transition duration-300"
            >
              Continue To Billing
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkout;
