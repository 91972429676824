import React from 'react';
import { Link } from 'react-router-dom';
import ReactStars from 'react-stars';
import { FaHeart } from 'react-icons/fa';
import { useProductContext, useWishlistContext } from '../../context';
 
const ProductCard = ({ data = [] }) => {
    const { addRecentProduct } = useProductContext();
    const { addToWishlist, Wishlist = [] } = useWishlistContext(); // Default Wishlist to an empty array
 
    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 px-4 py-8">
            {data.map((item) => {
                const { _id, name, image, currency = '₹', MRP_price, selling_price, discounted = null, rating = 0 } = item;
 
                // Handle both single image and array of images
                const imageUrl = Array.isArray(image) ? image[0] : image;
 
                // Check if the item is in the wishlist
                const isInWishlist = Array.isArray(Wishlist) && Wishlist.some((wishlistItem) => wishlistItem._id === _id);
 
                return (
                    <div key={_id}>
                        <div className="relative w-full h-80">
                            <Link to={`/product/${_id}`} onClick={() => addRecentProduct(item)}>
                                <img
                                    src={imageUrl}
                                    alt={name}
                                    className="w-full h-full object-cover"
                                    style={{borderRadius:'15%'}}
                                />
                            </Link>
                            <button
                                className="absolute top-3 right-3 bg-white p-2 rounded-full hover:bg-gray-200 transition"
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevent the event from bubbling up to the link
                                    addToWishlist(item);
                                }}
                            >
                                {/* Change the heart color based on wishlist status */}
                                <FaHeart className={`text-gray-500 ${isInWishlist ? 'text-red-500' : ''}`} />
                            </button>
                        </div>
                        <div className="p-1 h-20" style={{ borderRadius:'10px', color:'black' , backgroundColor: '#f8e9ca'}}>
                            <h3 className="text-sm font-semibold truncate">{name}</h3>
                            <div className="flex justify-between items-center mt-2">
                                <span className="text-sm font-bold">{`${currency}${selling_price}`}</span>
                                {MRP_price && (
                                    <span className="line-through text-sm text-red-500">{`${currency}${MRP_price}`}</span>
                                )}
                            </div>
                            <div className=" flex items-center">
                                <ReactStars
                                    count={5}
                                    value={rating}
                                    size={20}
                                    edit={false}
                                    activeColor="#ffd700"
                                />
                                <span className="ml-2 text-sm text-gray-600">({rating})</span>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
 
 
export default ProductCard;
 
 