import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoEyeOffOutline } from "react-icons/io5";
import { IoEyeOutline } from "react-icons/io5";
import { useAuthContext } from '../context';

const Login = () => {
    const { loginHandler, token, loading } = useAuthContext();
    const navigate = useNavigate();
    const location = useLocation();
    const [show, setShow] = useState(false);
    const [loginCredential, setLoginCredentials] = useState({
        email: "",
        password: "",
    });

    const handleSubmit = async (e) => {
        e.preventDefault();  
        loginHandler(loginCredential);
    };

    useEffect(() => {
        // Ensure that login is successful and token is set before redirecting
        if (token) {
            // Use location state to redirect to the previous page or default to home
            window.location.href = location?.state?.from?.pathname ?? "/";
        }
    }, [token, location]); // Depend on `token` to trigger re-navigation on login

    return (
        <>
            <div className="h-[84vh] sm:h-[85vh] bg-no-repeat bg-cover bg-center"
                style={{ backgroundImage: "url('https://images.unsplash.com/photo-1486520299386-6d106b22014b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80')" }}>
                <div className="flex justify-end">
                    <div className="bg-white h-[84vh] sm:h-[85vh] w-full sm:w-1/2 flex justify-center items-center">
                        <div>
                            <form>
                                <div>
                                    <h1 className="text-2xl font-bold">Login Here</h1>
                                </div>
                                <div className="my-3">
                                    <label className="block text-md mb-2" htmlFor="email">Email</label>
                                    <input className="px-4 w-full border-2 py-2 rounded-md text-sm outline-none" type="email" name="email" placeholder="email" onChange={(e) => setLoginCredentials({ ...loginCredential, email: e.target.value })} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required />
                                </div>
                                <div className="mt-5 relative">
                                    <label className="block text-md mb-2" htmlFor="password">Password</label>
                                    <input className="px-4 w-full border-2 py-2 rounded-md text-sm outline-none" type={show ? "text" : "password"} name="password" placeholder="password" onChange={(e) => setLoginCredentials({ ...loginCredential, password: e.target.value })} required />
                                    {show ? <IoEyeOffOutline className='absolute top-[60%] right-2' onClick={() => setShow(false)} />
                                        : <IoEyeOutline className='absolute top-[60%] right-2' onClick={() => setShow(true)} />}
                                </div>

                                <div className="flex justify-between">
                                    <span className="text-sm text-blue-700 hover:underline cursor-pointer">Forgot password?</span>
                                </div>
                                <div className="">
                                    <button className="mt-4 mb-3 w-full bg-green-500 hover:bg-green-400 text-white py-2 rounded-md flex items-center justify-center gap-3" type='button' onClick={handleSubmit}>
                                        {loading ? <img src='./loader/loader.gif' alt="Loading..." /> : null} 
                                        Login
                                    </button>
                                </div>
                            </form>
                            <p className="mt-8"> Don't have an account? <span className="cursor-pointer text-sm text-blue-600" onClick={() => window.location.href = "/signup"}> Sign up </span></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
