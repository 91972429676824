import React, { useEffect, useState } from 'react';
import { getAllOrdersURL, updateOrderStatusURL, createNotificationURL } from '../api/api';
import axios from 'axios';
import { IoCloseSharp } from 'react-icons/io5';
 
const AdminPanel = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [updatingOrderId, setUpdatingOrderId] = useState(null);
  const [showOrdersPopup, setShowOrdersPopup] = useState(false);
  const [notificationForm, setNotificationForm] = useState({
    notification_id: '',
    email: '',
    title: '',
    message: '',
    type: '',
  });
  const [notificationError, setNotificationError] = useState('');
  const [notificationSuccess, setNotificationSuccess] = useState('');
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const token = localStorage.getItem('token');
 
  const allStatuses = [
    "Change Status",
    "Order Placed",
    "Out For Delivery",
    "Delivered",
    "Cancelled",
    "Unverified Payment",
    "Payment Successful - Verification Pending",
  ];
 
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(getAllOrdersURL, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setOrders(response.data);
        localStorage.setItem('adminOrders', JSON.stringify(response.data));
        setLoading(false);
      } catch (error) {
        setError('Error fetching orders: ' + error.message);
        setLoading(false);
      }
    };
    fetchOrders();
  }, [token]);
 
  const handleStatusChange = async (orderId, newStatus, customerEmail) => {
    setUpdatingOrderId(orderId);
    try {
      // Step 1: Update the order status
      const response = await axios.put(updateOrderStatusURL, {
        order_id: orderId,
        status: newStatus,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
 
      if (response.status === 200) {
        // Step 2: Update the orders state in the frontend
        setOrders((prevOrders) => {
          const updatedOrders = prevOrders.map((order) =>
            order.order_id === orderId ? { ...order, status: newStatus } : order
          );
          return updatedOrders;
        });
 
        // Step 3: Generate the notification details
        const notification = {
          notification_id: `notif_${orderId}_${newStatus}`,
          email: customerEmail,
          title: `Order Status Updated: ${newStatus}`,
          message: `Your order status is now: ${newStatus}`,
          type: 'Info',
        };
 
        // Step 4: Send the notification to the customer
        await axios.post(createNotificationURL, notification, {
          headers: { Authorization: `Bearer ${token}` },
        });
 
        // Step 5: Optionally, handle success state
        setNotificationSuccess('Notification sent successfully!');
        setNotificationError('');
      } else {
        setError('Failed to update status: ' + response.data.message);
      }
    } catch (error) {
      setError('Error updating status: ' + error.message);
    } finally {
      setUpdatingOrderId(null);
    }
  };
 
  const handleNotificationChange = (e) => {
    const { name, value } = e.target;
    setNotificationForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };
 
  const handleCreateNotification = async (e) => {
    e.preventDefault();
    const emailToSend = notificationForm.email.trim() === '' ? null : notificationForm.email;
 
    try {
      const response = await axios.post(createNotificationURL, {
        ...notificationForm,
        email: emailToSend,  // Send null if email is empty
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
 
      if (response.status === 201) {
        setNotificationSuccess('Notification created successfully!');
        setNotificationForm({
          notification_id: '',
          email: '',
          title: '',
          message: '',
          type: '',
        });
        setNotificationError('');
        setShowNotificationPopup(false); // Close popup on success
      }
    } catch (error) {
      setNotificationError('Error creating notification: ' + error.response?.data?.message || error.message);
    }
  };
 
  const openNotificationPopup = () => setShowNotificationPopup(true);
  const closeNotificationPopup = () => setShowNotificationPopup(false);
  const openOrdersPopup = () => setShowOrdersPopup(true);
  const closeOrdersPopup = () => setShowOrdersPopup(false);
 
  if (loading) return <div className="text-center text-gray-600">Loading orders...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;
 
  return (
    <div className="p-8">
      <h1 className="text-center text-3xl font-bold text-gray-800 mb-6">Admin Panel</h1>
 
      <div className='flex flex-col sm:flex-row gap-12'>
        <div className='w-full sm:w-64'>
          <div className="bg-white shadow-md p-4 rounded-md hover:shadow-lg transition">
            <img
              src="material-management.png"
              alt="Orders"
              className="w-[80vw] sm:w-[15vw] h-auto mx-auto rounded-t-md"
            />
            <p classname="text-black-600 text-sm"><b>To check or change the Order Status.</b></p>
            <div className="text-center mt-2">
              <button
                onClick={openOrdersPopup}
                className="bg-blue-500 text-white px-4 py-2 rounded-md w-full hover:bg-blue-600"
              >
                Orders Status
              </button>
            </div>
          </div>
        </div>
 
        <div className='w-full sm:w-64'>
          <div className="bg-white shadow-md p-4 rounded-md hover:shadow-lg transition">
            <img
              src="bell.png"
              alt="Notification"
              className="w-[80vw] sm:w-[15vw] h-auto mx-auto rounded-t-md"
            /><p classname="text-black-600 text-sm"><b>Create and send notifications to users.</b></p>
            <div className="text-center mt-2">
              <button
                onClick={openNotificationPopup}
                className="bg-green-500 text-white px-4 py-2 rounded-md w-full hover:bg-green-600"
              >
                Create Notification
              </button>
            </div>
          </div>
        </div>
      </div>
 
      {/* Orders Popup Modal */}
      {showOrdersPopup && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-md w-full max-w-4xl overflow-x-auto relative">
            <button
              onClick={closeOrdersPopup}
              className="absolute top-4 right-4 text-red-500 hover:underline text-bold"
            >
              <IoCloseSharp size='30' />
            </button>
            <div className="flex flex-cols mb-4">
              <h2 className="text-xl font-semibold text-gray-800">Orders</h2>
            </div>
            <div className="overflow-x-auto bg-white shadow-lg rounded-lg mb-8 mt-4">
              <table className="min-w-full table-auto bg-gray-50 border-collapse">
                <thead className="bg-gray-200">
                  <tr>
                    <th className="py-3 px-6 text-left text-sm font-medium">Order ID</th>
                    <th className="py-3 px-6 text-left text-sm font-medium">Customer Name</th>
                    <th className="py-3 px-6 text-left text-sm font-medium">Order Status</th>
                    <th className="py-3 px-6 text-left text-sm font-medium">Product Details</th>
                    <th className="py-3 px-10 text-left text-sm font-medium">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => (
                    <tr key={order.order_id} className="border-b hover:bg-gray-100 transition-colors">
                      <td className="py-4 px-6 text-sm">{order.order_id}</td>
                      <td className="py-4 px-6 text-sm">{order.firstName} {order.lastName}</td>
                      <td className="py-4 px-6 text-sm text-center">{order.status}</td>
                      <td className="py-4 px-6 text-sm">
                        {order.product_details?.length > 0 ? (
                          <ul>
                            {order.product_details.map((product) => (
                              <li key={product.product_id}>
                                {product.product_name} (Quantity: {product.quantity})
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <p>No products available</p>
                        )}
                      </td>
                      <td className="py-4 px-6 text-sm text-center">
                        <select
                          onChange={(e) => handleStatusChange(order.order_id, e.target.value, order.email)}
                          value={order.status}
                          className="border rounded-md px-3 py-1"
                        >
                          {allStatuses.map((status) => (
                            <option key={status} value={status}>
                              {status}
                            </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
 
      {/* Notification Popup Modal */}
      {showNotificationPopup && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-md w-full max-w-lg overflow-x-auto relative">
            <button
              onClick={closeNotificationPopup}
              className="absolute top-4 right-4 text-red-500 hover:underline text-bold"
            >
              <IoCloseSharp size='30' />
            </button>
            <h2 className="text-xl font-semibold mb-4">Create a Notification</h2>
            <form onSubmit={handleCreateNotification}>
              {notificationError && <div className="text-red-500 mb-2">{notificationError}</div>}
              {notificationSuccess && <div className="text-green-500 mb-2">{notificationSuccess}</div>}
              <div className="mb-4">
                <label htmlFor="notification_id" className="block text-sm font-medium text-gray-700">Notification Id</label>
                <input
                  type="text"
                  id="notification_id"
                  name="notification_id"
                  className="w-full p-2 mt-2 border rounded-md"
                  value={notificationForm.notification_id}
                  onChange={handleNotificationChange}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email (optional)</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="w-full p-2 mt-2 border rounded-md"
                  value={notificationForm.email}
                  onChange={handleNotificationChange}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="title" className="block text-sm font-medium text-gray-700">Title</label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  className="w-full p-2 mt-2 border rounded-md"
                  value={notificationForm.title}
                  onChange={handleNotificationChange}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="message" className="block text-sm font-medium text-gray-700">Message</label>
                <textarea
                  id="message"
                  name="message"
                  className="w-full p-2 mt-2 border rounded-md"
                  value={notificationForm.message}
                  onChange={handleNotificationChange}
                ></textarea>
              </div>
              <div className="mb-4">
                <label htmlFor="type" className="block text-sm font-medium text-gray-700">Type</label>
                <select
                  id="type"
                  name="type"
                  className="w-full p-2 mt-2 border rounded-md"
                  value={notificationForm.type}
                  onChange={handleNotificationChange}
                >
                  <option value="Info">Info</option>
                  <option value="Warning">Warning</option>
                  <option value="Error">Error</option>
                </select>
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                >
                  Send Notification
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
 
export default AdminPanel;
 
 
 