import React, { useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MdClose } from "react-icons/md";
import { useWishlistContext } from "../context/index";

const WishlistPage = ({ setWishlist, wishlist }) => {
    const navigate = useNavigate();
    const { Wishlist, removeToWish } = useWishlistContext();
    const wishlistRef = useRef(null); // Wishlist reference

    useEffect(() => {
        // Only add event listener if the wishlist is open
        const handleClickOutside = (event) => {
            if (wishlist && wishlistRef.current && !wishlistRef.current.contains(event.target)) {
                setWishlist(false); // Close wishlist if click is outside
            }
        };

        if (wishlist) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        // Clean up event listener
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wishlist, setWishlist]);

    // Render nothing if wishlist is not open
    if (!wishlist) {
        return <div style={{ display: 'none' }} />;
    }

    return (
        <div className="fixed inset-0 z-50">
            {/* Background overlay */}
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-40"></div>

            {/* Wishlist container */}
            <div ref={wishlistRef} className="fixed inset-y-0 right-0 flex max-w-full pl-10 z-50">
                <div className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                        <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                            <div className="flex items-start justify-between">
                                <h2 className="text-lg font-medium text-gray-900" id="slide-over-title">Wishlist</h2>
                                <div className="ml-3 flex h-7 items-center">
                                    <button
                                        type="button"
                                        className="relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                                        onClick={() => setWishlist(false)}
                                    >
                                        <span className="sr-only">Close panel</span>
                                        <MdClose />
                                    </button>
                                </div>
                            </div>
                            <div className="mt-8">
                                <div className="flow-root">
                                    <ul role="list" className="-my-6 divide-y divide-gray-200">
                                        {Wishlist?.map((item) => (
                                            <li className="flex py-6" key={item._id}>
                                                <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                                    <img src={item.image[0]} alt={item.name} className="h-full w-full object-cover object-center" />
                                                </div>
                                                <div className="ml-4 flex flex-1 flex-col">
                                                    <div>
                                                        <div className="flex justify-between text-base font-medium text-gray-900">
                                                            <h3>
                                                                <Link to={`/product/${item._id}`}>{item.name.slice(0, 18)}...</Link>
                                                            </h3>
                                                            <p className="ml-4">{`₹${item.selling_price}`}</p>
                                                        </div>
                                                        <p className="mt-1 text-sm text-gray-500">{item.brand}</p>
                                                    </div>
                                                    <div className="flex flex-1 items-end justify-between text-sm">
                                                        <button
                                                            type="button"
                                                            className="font-medium text-indigo-600 hover:text-indigo-500"
                                                            onClick={() => removeToWish(item)}
                                                        >
                                                            Remove
                                                        </button>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                            <button
                                type="button"
                                className="font-medium text-indigo-600 hover:text-indigo-500"
                                onClick={() => setWishlist(false)}
                            >
                                Continue Shopping
                            </button>
                            <button
                                type="button"
                                className="ml-4 font-medium text-indigo-600 hover:text-indigo-500"
                                onClick={() => navigate('/productlist')}
                            >
                                View All Products
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WishlistPage;
