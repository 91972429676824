import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { createReviewURL, updateOrderStatusURL } from "../api/api";
 
const Orders = () => {
  const [orderList, setOrderList] = useState([]);
  const [productData, setProductData] = useState([]);
  const [isReviewOpen, setIsReviewOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedOrderProducts, setSelectedOrderProducts] = useState([]);
  const [reviewText, setReviewText] = useState('');
  const [rating, setRating] = useState(0);
  const [userName, setUserName] = useState('');
 
  const navigate = useNavigate();
 
  useEffect(() => {
    const storedOrders = JSON.parse(localStorage.getItem('orders')) || [];
    const userData = JSON.parse(localStorage.getItem('userData')) || {};
    const fullName = `${userData.firstName || ''} ${userData.lastName || ''}`.trim();
    setUserName(fullName);
    const storedProducts = JSON.parse(localStorage.getItem('products')) || [];
    setOrderList(storedOrders);
    setProductData(storedProducts);
  }, []);
 
  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = d.getFullYear();
    const hours = String(d.getHours()).padStart(2, '0');    
    const minutes = String(d.getMinutes()).padStart(2, '0');
    const seconds = String(d.getSeconds()).padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
 
  };
 
  const findProductDetails = (skuId) => {
    return productData.find(product => product.SKU_id === skuId);
  };
 
  const navigateToProductDetails = (skuId) => {
    const product = findProductDetails(skuId);
    if (product) {
      navigate(`/product/${product._id}`);
    } else {
      console.error("Product not found for SKU:", skuId);
    }
  };
 
  const statusFlow = ['Order Placed', 'Out For Delivery', 'Delivered'];

const renderStatusFlow = (currentStatus, products, orderId) => {
  const isVerificationStatus =
    currentStatus === 'Unverified Payment' ||
    currentStatus === 'Payment Successful - Verification Pending';

  return (
    <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-2 mt-4">
      {currentStatus === 'Cancelled' ? (
        <div className="flex items-center">
          <div className="px-4 py-2 rounded-full font-semibold text-sm bg-red-500 text-white">
            Cancelled
          </div>
        </div>
      ) : isVerificationStatus ? (
        // Display a single status badge for verification statuses
        <div className="flex items-center">
          <div className="px-4 py-2 rounded-full font-semibold text-sm bg-yellow-500 text-white">
            {currentStatus}
          </div>
        </div>
      ) : (
        // Render the status flow for normal order statuses
        statusFlow.map((status, index) => (
          <div key={index} className="flex items-center">
            <div
              className={`px-4 py-2 rounded-full font-semibold text-sm ${
                currentStatus === status
                  ? 'bg-green-500 text-white'
                  : index < statusFlow.indexOf(currentStatus)
                  ? 'bg-green-200 text-gray-600'
                  : 'bg-gray-200 text-gray-400'
              }`}
            >
              {status}
            </div>
            {index < statusFlow.length - 1 && (
              <span className="mx-1 text-gray-400 hidden sm:inline">→</span>
            )}
          </div>
        ))
      )}
      {(currentStatus === 'Delivered' || currentStatus === 'Cancelled') && (
        <button
          onClick={() => openReviewModal(products)}
          className="ml-3 px-4 sm:px-8 py-2 bg-blue-500 text-white rounded-full text-sm"
        >
          Write a Review
        </button>
      )}
      {currentStatus === 'Order Placed' && (
        <button
          onClick={() => cancelOrder(orderId)}
          className="ml-3 px-4 sm:px-8 py-2 bg-red-500 text-white rounded-full text-sm"
        >
          Cancel Order
        </button>
      )}
    </div>
  );
};

  const openReviewModal = (products) => {
    setSelectedOrderProducts(products);
    setSelectedProduct(products[0]?.SKU_id || null);
    setIsReviewOpen(true);
  };
 
  const closeReviewModal = () => {
    setIsReviewOpen(false);
    setReviewText('');
    setRating(0);
    setSelectedProduct(null);
  };
 
  const handleReviewSubmit = async () => {
    if (!selectedProduct || !reviewText.trim() || rating < 1 || rating > 5) {
      alert('Please fill out all fields and ensure the rating is between 1 and 5.');
      return;
    }
 
    try {
      const token = localStorage.getItem('token');
      const reviewData = {
        user_name: userName,
        user_rating: rating,
        user_review: reviewText,
        SKU_Id: selectedProduct,
      };
 
      const response = await axios.post(createReviewURL, reviewData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
 
      if (response.status === 201) {
        alert('Review submitted successfully');
        const existingReviews = JSON.parse(localStorage.getItem("reviews")) || [];
        const updatedReviews = [...existingReviews, response.data.data];
        localStorage.setItem("reviews", JSON.stringify(updatedReviews));
        closeReviewModal();
      } else {
        console.error("Unexpected response:", response);
        alert("Failed to submit review");
      }
    } catch (error) {
      console.error("Review submission error:", error);
      alert("Error submitting review. Please try again.");
    }
  };

  const cancelOrder = async (orderId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(updateOrderStatusURL, { order_id: orderId, status: 'Cancelled' }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      // Check if the response status indicates success
      if (response.status === 200) {
        alert(response.data.message); // Display the backend's message
        const updatedOrders = orderList.map(order =>
          order.order_id === orderId ? { ...order, status: 'Cancelled' } : order
        );
        setOrderList(updatedOrders);
        localStorage.setItem('orders', JSON.stringify(updatedOrders));
      } else {
        alert(response.data.message || 'Failed to cancel order'); // Use backend message or fallback
      }
    } catch (error) {
      console.error("Order cancellation error:", error);
      // Use error response message if available
      const errorMessage = error.response?.data?.message || "Error cancelling order. Please try again.";
      alert(errorMessage);
    }
  };
  
 
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center py-10 px-4 sm:px-0">
      <h1 className="text-3xl sm:text-4xl font-bold text-gray-800 mb-6 sm:mb-10">Your Orders</h1>
 
      <div className="w-full max-w-2xl sm:max-w-4xl px-3 sm:px-5">
        {orderList.length > 0 ? (
          orderList.map((order, index) => (
            <div key={index} className="bg-white shadow-md rounded-lg overflow-hidden mb-8">
              <div className="px-4 sm:px-6 py-4 border-b border-gray-200">
                <h3 className="font-semibold text-lg sm:text-xl text-gray-800 mb-4">Order Details:</h3>
                <div className={`grid gap-4 ${order.product_details.length === 1 ? 'grid-cols-1 text-center' : 'md:grid-cols-2'}`}>
                  {order.product_details.map((product, idx) => {
                    const productInfo = findProductDetails(product.SKU_id);
                    return (
                      <div
                        key={idx}
                        className="flex items-center bg-gray-50 rounded-lg shadow-sm p-4 cursor-pointer"
                        onClick={() => navigateToProductDetails(product.SKU_id)}
                      >
                        <img src={productInfo?.image[0]} alt={productInfo?.name} className="rounded-md mb-2 w-16 h-16 sm:w-20 sm:h-20 mr-4" />
                        <div>
                          <h4 className="font-semibold text-base sm:text-lg text-gray-800">{productInfo?.name || 'Product Name'}</h4>
                          <p className="text-sm text-gray-500">Quantity: {product.quantity}</p>
                          <p className="text-sm text-gray-500">Price: ₹{product.price}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
 
              <div className="px-4 sm:px-6 py-4 border-b border-gray-200">
                <h3 className="font-semibold text-lg sm:text-xl text-gray-800 mb-2">Order Summary:</h3>
                <p className="text-gray-700 mb-2">
                  Order ID: <span className="font-semibold text-blue-600">{order.order_id}</span>
                </p>
                <p className="text-gray-500 text-sm mb-2">
                  Date Placed: <span className="font-medium">{formatDate(order.createdAt)}</span>
                </p>
                <p className="text-gray-700 mb-4">
                  Total Amount: <span className="font-bold text-green-600">₹{order.amount}</span>
                </p>
                <p className="text-gray-600 text-sm mb-4 pl-4 border-l-4 border-blue-500">
                  Payment Status: {order.payment.status}, Method: {order.payment.method}
                </p>
                <p className="font-semibold text-gray-700 mb-2">Shipping Address:</p>
                <p className="text-gray-600 text-sm mb-4 pl-4 border-l-4 border-blue-500">
                  {order.shipping_address
                    ? `${order.shipping_address.addressLine1}, ${order.shipping_address.addressLine2}, ${order.shipping_address.city}, ${order.shipping_address.state} - ${order.shipping_address.postalCode}`
                    : 'Address not available'}
                </p>
              </div>
 
              <div className="px-4 sm:px-6 py-4">
                {renderStatusFlow(order.status, order.product_details, order.order_id)}
              </div>
            </div>
          ))
        ) : (
          <div className="text-center text-lg sm:text-xl text-gray-500">
            No orders available.
          </div>
        )}
      </div>
 
      {isReviewOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
            <h3 className="text-lg sm:text-xl font-semibold mb-4">Write a Review</h3>
            <select
              value={selectedProduct}
              onChange={(e) => setSelectedProduct(e.target.value)}
              className="block w-full mb-4 p-2 border rounded-md"
            >
              {selectedOrderProducts.map((product) => (
                <option key={product.SKU_id} value={product.SKU_id}>
                  {findProductDetails(product.SKU_id)?.name}
                </option>
              ))}
            </select>
 
            <textarea
              value={reviewText}
              onChange={(e) => setReviewText(e.target.value)}
              rows="4"
              placeholder="Write your review..."
              className="w-full p-2 mb-4 border rounded-md"
            ></textarea>
 
            <div className="flex items-center mb-4">
              <label htmlFor="rating" className="mr-4">Rating:</label>
              <input
                type="number"
                id="rating"
                value={rating}
                onChange={(e) => setRating(Math.max(0, Math.min(5, Number(e.target.value))))} // Ensure value is between 0 and 5
                min="0"
                max="5"
                className="p-2 border rounded-md w-20"
              />
            </div>
 
            <div className="flex justify-end space-x-4">
              <button
                onClick={handleReviewSubmit}
                className="px-4 py-2 bg-blue-500 text-white rounded-md"
              >
                Submit Review
              </button>
              <button
                onClick={closeReviewModal}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
 
export default Orders;
 
 